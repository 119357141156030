import React from "react"

import { slide as Menu } from "react-burger-menu"
import { Link } from "gatsby"

import styles from "./navbar.module.scss"
import "./navbar.scss"

class Navbar extends React.Component {
  showSettings(event) {
    event.preventDefault()
  }

  render() {
    return (
      <div className={styles["navbar"]}>
        {/* Branding */}
        <div className={styles["branding"]}>
          <Link to="/">VietJS</Link>
        </div>
        {/* Menu */}

        {/* BurgerMenu */}
        <Menu right>
          <Link
            id="home"
            className="menu-item"
            to="/"
            activeClassName={styles.active}
          >
            Home
          </Link>

          <Link
            id="projects"
            className="menu-item"
            to="/projects"
            activeClassName={styles.active}
          >
            Projects
          </Link>
          <Link
            id="blogs"
            className="menu-item"
            to="/blogs"
            activeClassName={styles.active}
          >
            Blogs
          </Link>
          <Link
            id="about"
            className="menu-item"
            to="/about"
            activeClassName={styles.active}
          >
            About
          </Link>
          <Link
            id="blogs"
            className="menu-item"
            to="/contact"
            activeClassName={styles.active}
          >
            Contact
          </Link>
        </Menu>
        {/* Normal Menu */}
        <div className={styles["menu"]}>
          <ul className={styles["menu-items"]}>
            <li>
              <Link to="/" activeClassName={styles.active}>
                Home
              </Link>
            </li>
            <li>
              <Link to="/projects" activeClassName={styles.active}>
                Projects
              </Link>
            </li>
            <li>
              <Link to="/blogs" activeClassName={styles.active}>
                Blogs
              </Link>
            </li>
            <li>
              <Link to="/about" activeClassName={styles.active}>
                About
              </Link>
            </li>
            <li>
              <Link to="/contact" activeClassName={styles.active}>
                Contact
              </Link>
            </li>
          </ul>
        </div>
      </div>
    )
  }
}

export default Navbar

import React from "react"
import styles from "./media-icon.module.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const MediaIcon = ({ linkUrl, name, isBrand, icon }) => {
  return (
    <div className="icon-container">
      <a href={linkUrl} target="_blank" rel="noopener noreferrer">
        {icon ? (
          <FontAwesomeIcon className={styles.icon} icon={icon} />
        ) : isBrand ? (
          <FontAwesomeIcon className={styles.icon} icon={["fab", name]} />
        ) : (
          <FontAwesomeIcon className={styles.icon} icon={name} />
        )}
      </a>
    </div>
  )
}

export default MediaIcon

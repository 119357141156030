import React from "react"
import "./layout.scss"

import Navbar from "./navbar/navbar.component"
import Footer from "./footer/footer.component"

const Layout = props => {
  return (
    <div className="page-container">
      <Navbar />
      <div className="content">{props.children}</div>
      <Footer />
    </div>
  )
}

export default Layout

import React from "react"
import styles from "./footer.module.scss"
import MediaIcon from "../media-icon/media-icon.component"
// import data
import contactInfo from "../../../site/settings/contact_info.json"
const Footer = () => {
  return (
    <div className={styles["footer"]}>
      <div className="author">© 2020 Viet Nguyen</div>
      <div className={styles["social-networks"]}>
        <MediaIcon linkUrl={contactInfo.github} name="github" isBrand={true} />
        <MediaIcon
          linkUrl={contactInfo.linkedin}
          name="linkedin"
          isBrand={true}
        />
        <MediaIcon
          linkUrl={contactInfo.twitter}
          name="twitter"
          isBrand={true}
        />
      </div>
    </div>
  )
}

export default Footer
